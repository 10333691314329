<template>
    <div class="container-fluid px-0">
        <div class="row row-cols-1 row-cols-lg-2 mb-n4">
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Basic Breadcrumb</div>
                    <div class="card-body">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item active" aria-current="page">UI Elements</li>
                            </ol>
                        </nav>

                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="#">UI Elements</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Breadcrumb</li>
                            </ol>
                        </nav>

                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="#">UI Elements</a></li>
                                <li class="breadcrumb-item"><a href="#">Breadcrumb</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Basic Example</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">With Icon</div>
                    <div class="card-body">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="#"><fa icon="puzzle-piece" /> UI Elements</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Breadcrumb</li>
                            </ol>
                        </nav>

                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="#"><fa icon="puzzle-piece" /> UI Elements</a></li>
                                <li class="breadcrumb-item"><a href="#">Breadcrumb</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Basic Example</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Breadcrumb'
    }
</script>
